import axios from "axios";
import { FAKE_POPULARS, FAKE_RECOMMENDATIONS } from "./fake_data";
import { BASE_URL, API_KEY_PARAM } from "../config";

export class TVShowAPI {
  static async fetchPopulars() {
    const response = await axios.get(
      //`${BASE_URL}tv/popular?language=fr-FR&api_key=${API_KEY_PARAM}`
      `${BASE_URL}movie/now_playing?language=fr-FR&api_key=${API_KEY_PARAM}`
    );
    return response.data.results;
    // return FAKE_POPULARS;
  }

  static async fetchRecommendations(tvShowId) {
    const response = await axios.get(
      //`${BASE_URL}tv/${tvShowId}/recommendations?language=fr-FR&api_key=${API_KEY_PARAM}`
      `${BASE_URL}movie/${tvShowId}/recommendations?language=fr-FR&api_key=${API_KEY_PARAM}`
    );
    return response.data.results;
    // return FAKE_RECOMMENDATIONS;
  }

  static async fetchByTitle(title) {
    const response = await axios.get(
      //`${BASE_URL}search/movie?language=fr-FR&api_key=${API_KEY_PARAM}&query=${title}`
      `${BASE_URL}search/movie?language=fr-FR&api_key=${API_KEY_PARAM}&query=${title}`
    );
    return response.data.results;
  }
}
