import { TVShowListItem } from "../TVShowListItem/TVShowListItem";
import s from "./style.module.css";

export function TVShowList({ tvShowList, onClickItem }) {
  return (
    <div>
      <div className={s.title}>Vous aimerez probablement :</div>
      <div className={s.list}>
        {tvShowList.map((tvShow) => {
          return (
            <span className={s.tv_show_item} key={tvShow.id}>
              {tvShow.backdrop_path !== null && (
                <TVShowListItem tvShow={tvShow} onClick={onClickItem} />
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
}
